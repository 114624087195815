import React from 'react';
import { useState } from 'react';
import { Link, graphql } from "gatsby";
import {Layout, HeadlinePanel, MetaTags}  from '../components';
import { getPostsFromGraphQLGroup, slugify } from "../utils";
import ChartIcon from '../components/ChartIcon';
import styles from './tags.module.css';
import _ from 'lodash';


const Dropdown = ({ options, selectedOption, includeVariations=false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOutsideClick = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownContainer}>
      <div className={`${styles.selectedOption} ${isOpen ? styles.dropdownOpen : ''}`} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption} <span className={`${styles.arrow} ${isOpen ? 'up' : 'down'}`}>&#9660;</span>
      </div>
      {isOpen && (
        <>
          <div className={styles.backdrop} onClick={handleOutsideClick}></div>
          <ul className={styles.optionsList}>
            {options.map((option) => (selectedOption === option 
              ?
                <li key={option} className={`${styles.option} ${styles.selected}`} onClick={() => setIsOpen(false)}>
                  <span>{option}</span>
                </li>
              :
                <Link to={"/" + _.kebabCase(option) + (includeVariations ? "/all" : "")}>
                  <li key={option} className={`${styles.option}`} onClick={() => setIsOpen(false)}>
                    <span>{option}</span>
                  </li>
                </Link>
              ))}
          </ul>
        </>
      )}
    </div>
  );
};

function TagsPage({pageContext, location, data}) {
    const {showVariations, tagName} = pageContext;
    const tagId = _.camelCase(tagName)+"s";
    const chartGroups = getPostsFromGraphQLGroup(data, showVariations, tagId);
    const {description, link} = data?.tagsYaml || {};
    const tags = ['function', 'visualization technique', 'information type']; // tags to switch

    const allVisualizationTechniques = data?.allVisualizationTechniquesYaml?.edges?.map( ({node}) => node );
    // map array to id:name
    var allVisualizationTechniquesMap = Object.fromEntries(
      allVisualizationTechniques?.map(e => [e.id, e.name])
    )
  
    // TODO: for charts that have variations show number of variations on hover
    // const chartTitle = (showVariations, post) => !showVariations && (post.variations > 0)
    //   ? post.title +" (" + post.variations + " variations)"
    //   : post.title
    return (
        <Layout>
            <MetaTags
              title={`Chart Functions list - Graphopedia`}
              description="See different function for each graph on Graphopedia."
            />
            <div className={styles.functions}>
                <HeadlinePanel pathname={location.pathname} includeVariations={showVariations} />
                <div className={styles.subHeadline}>
                  by <Dropdown 
                        selectedOption={tagName} 
                        options={tags} 
                        includeVariations={showVariations} 
                      />
                  </div>
                <div className={styles.description}>{description} {link && <a href={link}> Read more.</a>}</div>
                <ul className={styles.fList}>
                {
                  chartGroups.map(group => {
                    const groupName = tagName === "visualization technique" ? allVisualizationTechniquesMap[group.name] : group.name;
                    return (
                        <li>
                         {groupName && 
                          <h2 className={styles.fTitle}>
                            <Link to={`/${_.kebabCase(tagName)}/${slugify(groupName)}`} >
                              {groupName}
                            </Link>
                          </h2>
                         }
                          <ul className={styles.fCharts}>
                            {
                              group.charts.map( function({slug, title, variationOf, variations}) {
                                const displayVariations = !showVariations && (variations > 0);
                                return (
                                  <li>
                                    <Link to={"/" + slug} title={displayVariations ? `${title} (${variations} variations)` : title}>
                                    <span className={styles.iconWrapper}>
                                      <ChartIcon slug={slug} showStack={displayVariations} isVariation={variationOf}/>
                                    </span>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </li>
                  )})
                }
                </ul>
            </div>
        </Layout>
    )

}

export default TagsPage;


export const pageQuery = graphql`
query AllTagsQuery($tagName: String!, $incFunction: Boolean!, $incVisPrinciple: Boolean!, $incAxis: Boolean!, $incInfo: Boolean!) {
  tagsYaml(name: {eq: $tagName}) {
    description,
    link
  }
  functions: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}) @include(if: $incFunction) {
    group(field: frontmatter___functions) {
      fieldValue
      totalCount
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            variationOf
          }
        }
      }
    }
  }
  visualizationTechniques: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}) @include(if: $incVisPrinciple) {
    group(field: frontmatter___visualizationTechniques___techniqueId) {
      fieldValue
      totalCount
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            variationOf
          }
        }
      }
    }
  }
  informationTypes: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}) @include(if: $incInfo) {
    group(field: frontmatter___visualizationTechniques___informationType) {
      fieldValue
      totalCount
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            variationOf
          }
        }
      }
    }
  }
  axisDirections: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}) @include(if: $incAxis) {
    group(field: frontmatter___visualizationTechniques___axisDirection) {
      fieldValue
      totalCount
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            variationOf
          }
        }
      }
    }
  }
  allVisualizationTechniquesYaml {
    edges {
      node {
        id,
        name
      }
    }
  }
}
`
